/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/modals.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

ion-label {
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.scanner-modal-css .modal-wrapper {
  height: 60vh;
  width: 75vw;
  position: absolute;
  display: block;
  border-radius: 20px;
  border: 2.5px white solid;
}

ion-backdrop{
  	opacity:0;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.loading-modal-css .loading-wrapper {
  background: var(--ion-color-primary) !important;
  border-radius: 10px;
  min-height: 150px !important;
  min-width: 250px !important;

  .loading-content {
    color: var(--ion-color-light) !important;
  }

  .loading-spinner {
    --spinner-color: var(--ion-color-light) !important;
  }
  .loading-button-group {
    display: flex;
    justify-content: center;
    .loading-button {
      color: var(--ion-color-light) !important;
    }
  }
}

.my-custom-class .alert-wrapper {
  background: var(--ion-color-primary) !important;
  border-radius: 10px;
  min-width: 250px !important;

  .alert-title {
    color: var(--ion-color-light) !important;
    text-align: center;
  }

  .alert-message {
    color: var(--ion-color-light) !important;
    text-align: center;
  }
  .alert-button-group {
    display: flex;
    justify-content: center;
    .alert-button {
      color: var(--ion-color-light) !important;
    }
  }
}

.alert-wrapper {
  border-radius: 10px;
  min-width: 70vw !important;
  
  .alert-radio-icon {
    color: var(--ion-color-light) !important;
  }

  .alert-radio-label {
    color: var(--ion-color-dark) !important;
  }
}

//Angular MAterial Overrides
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #dfdfdf !important;
  border-radius: 5px !important;
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-underline{
  display: none;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #03adc7 !important;
}

.ngx-pagination {
  margin-bottom: 0.2rem !important;
  margin-top: 0.2rem !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.5em;
}

.mat-form-field-suffix{
  margin: auto;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 0 0.75em 0;
}