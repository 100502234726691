.coupon-modal-css .modal-wrapper {
  height: 30%;
  width: 30%;
  position: absolute;
  display: block;
  border-radius: 20px;
  border: 2.5px white solid;
  min-width: 250px;
}

.coupon-use-modal-css .modal-wrapper {
  height: 80%;
  width: 740px;
  border-radius: 20px;
  border: 1px white solid;
}